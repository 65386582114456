import React from "react";
import { Switch, Route } from "react-router-dom";

import SignInPage from './SignInPage'
import SignUpPage from './SignUpPage'
import SetUpPage from './SetUpPage'
import ForgotPasswordPage from './ForgotPasswordPage'
import ErrorPage from '../ErrorPage'

import landingPageSidebar from "../../images/onboarding-sidebar.svg";
import "./Onboarding.css";
import ResetPassword from "./ResetPassword";

const Onboarding = () => {
  return (

      <div className="mainPage">
        <div className="sidebarImg">
          <img src={landingPageSidebar} alt="sidebar"></img>
        </div>
        <div className="mainSection">
          <div className="form">
            <Switch>
              <Route exact path="/sign-up/setup" component={SetUpPage} />
              <Route exact path="/sign-up" component={SignUpPage} />
              <Route exact path="/forget-password" component={ForgotPasswordPage} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route exact path="/" component={SignInPage} />
              <Route path="*" component={ErrorPage} />
            </Switch>
          </div>
        </div>
      </div>
  );
};

export default Onboarding;
