import React from 'react';

interface IProps {
    active: boolean
}

const AnalyticsRender = ({ active }:IProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.75 0.75H2.25C1.42157 0.75 0.75 1.42157 0.75 2.25V21.75C0.75 22.5784 1.42157 23.25 2.25 23.25H21.75C22.5784 23.25 23.25 22.5784 23.25 21.75V2.25C23.25 1.42157 22.5784 0.75 21.75 0.75Z" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.75 12.7501H6.75L9.01 7.10008C9.06689 6.95923 9.16527 6.839 9.29207 6.75536C9.41888 6.67172 9.56812 6.62861 9.72 6.63175C9.87188 6.6349 10.0192 6.68415 10.1424 6.77297C10.2657 6.8618 10.359 6.98599 10.41 7.12908L13.702 16.4541C13.7498 16.5895 13.8355 16.7083 13.9489 16.7964C14.0622 16.8846 14.1985 16.9383 14.3415 16.9513C14.4845 16.9642 14.6283 16.9358 14.7556 16.8695C14.883 16.8032 14.9886 16.7017 15.06 16.5771L17.25 12.7501H20.25" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const UploadRender = ({ active }:IProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.75 18.751V21.751C0.75 22.1488 0.908035 22.5303 1.18934 22.8116C1.47064 23.0929 1.85218 23.251 2.25 23.251H5.25" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M0.75 5.25098V2.25098C0.75 1.85315 0.908035 1.47162 1.18934 1.19032C1.47064 0.909012 1.85218 0.750977 2.25 0.750977H5.25" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M0.75 14.251V9.75098" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M23.25 18.751V21.751C23.25 22.1488 23.092 22.5303 22.8107 22.8116C22.5294 23.0929 22.1478 23.251 21.75 23.251H18.75" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M23.25 5.25098V2.25098C23.25 1.85315 23.092 1.47162 22.8107 1.19032C22.5294 0.909012 22.1478 0.750977 21.75 0.750977H18.75" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M23.25 14.251V9.75098" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.25 0.750977H9.75" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.25 23.251H9.75" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 18.001V6.00098" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 10.501L12 6.00098L16.5 10.501" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const LibraryRender = ({ active }:IProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.75 1.5H2.25C1.42157 1.5 0.75 2.17157 0.75 3V18C0.75 18.8284 1.42157 19.5 2.25 19.5H18.75C19.5784 19.5 20.25 18.8284 20.25 18V3C20.25 2.17157 19.5784 1.5 18.75 1.5Z" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.25 22.5H21.75C22.1478 22.5 22.5294 22.342 22.8107 22.0607C23.092 21.7794 23.25 21.3979 23.25 21V5.80005" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.375 15.75C7.41053 15.75 8.25 14.9105 8.25 13.875C8.25 12.8395 7.41053 12 6.375 12C5.33947 12 4.5 12.8395 4.5 13.875C4.5 14.9105 5.33947 15.75 6.375 15.75Z" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.875 13.5C14.9105 13.5 15.75 12.6605 15.75 11.625C15.75 10.5895 14.9105 9.75 13.875 9.75C12.8395 9.75 12 10.5895 12 11.625C12 12.6605 12.8395 13.5 13.875 13.5Z" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.25 13.8751V7.25011C8.25003 6.93524 8.34914 6.62837 8.53328 6.37298C8.71743 6.11758 8.97727 5.9266 9.276 5.82711L13.776 4.57711C14.0015 4.50201 14.2415 4.48156 14.4764 4.51744C14.7114 4.55331 14.9344 4.64448 15.1272 4.78345C15.3199 4.92241 15.4769 5.10518 15.5852 5.31671C15.6935 5.52824 15.75 5.76247 15.75 6.00011V11.6251" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}

const SettingsRender = ({ active }:IProps) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.75 0.75H2.25C1.42157 0.75 0.75 1.42157 0.75 2.25V21.75C0.75 22.5784 1.42157 23.25 2.25 23.25H21.75C22.5784 23.25 23.25 22.5784 23.25 21.75V2.25C23.25 1.42157 22.5784 0.75 21.75 0.75Z" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.75 11.2649V5.26489" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.75 18.7649V15.7649" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.5149C18 13.9599 17.868 14.3949 17.6208 14.7649C17.3736 15.1349 17.0222 15.4233 16.611 15.5936C16.1999 15.7639 15.7475 15.8085 15.311 15.7217C14.8746 15.6348 14.4737 15.4206 14.159 15.1059C13.8443 14.7912 13.6301 14.3903 13.5432 13.9538C13.4564 13.5174 13.501 13.065 13.6713 12.6539C13.8416 12.2427 14.13 11.8913 14.5 11.6441C14.87 11.3969 15.305 11.2649 15.75 11.2649C16.3467 11.2649 16.919 11.5019 17.341 11.9239C17.7629 12.3459 18 12.9182 18 13.5149Z" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.25 9.76489V18.7649" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.51489C10.5 7.06989 10.368 6.63487 10.1208 6.26486C9.87357 5.89485 9.52217 5.60646 9.11104 5.43616C8.6999 5.26587 8.24751 5.22131 7.81105 5.30813C7.37459 5.39494 6.97368 5.60924 6.65901 5.9239C6.34434 6.23857 6.13005 6.63948 6.04323 7.07594C5.95642 7.5124 6.00097 7.9648 6.17127 8.37593C6.34157 8.78706 6.62996 9.13847 6.99997 9.3857C7.36998 9.63293 7.80499 9.76489 8.25 9.76489C8.84674 9.76489 9.41903 9.52784 9.84099 9.10588C10.2629 8.68393 10.5 8.11163 10.5 7.51489V7.51489Z" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const NotificationRender = ({ active }:IProps) => {
    return (
        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 21.75C8.12702 22.1827 8.39076 22.5626 8.75175 22.8328C9.11274 23.1031 9.55156 23.2492 10.0025 23.2492C10.4534 23.2492 10.8923 23.1031 11.2532 22.8328C11.6142 22.5626 11.878 22.1827 12.005 21.75" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 3V0.75" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 3C11.9891 3 13.8968 3.79018 15.3033 5.1967C16.7098 6.60322 17.5 8.51088 17.5 10.5C17.5 17.546 19 18.75 19 18.75H1C1 18.75 2.5 16.834 2.5 10.5C2.5 8.51088 3.29018 6.60322 4.6967 5.1967C6.10322 3.79018 8.01088 3 10 3V3Z" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const LogoutRender = ({ active }:IProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 12.0039H23.25" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.5 15.7539L23.25 12.0039L19.5 8.25391" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.75 16.5V21C15.7671 21.3796 15.6332 21.7505 15.3776 22.0316C15.122 22.3127 14.7655 22.4811 14.386 22.5H2.11298C1.73369 22.4808 1.3774 22.3123 1.122 22.0312C0.866599 21.7501 0.732849 21.3794 0.749983 21V3C0.732582 2.62054 0.866241 2.24964 1.1217 1.96851C1.37715 1.68737 1.73359 1.51891 2.11298 1.5H14.386C14.7655 1.51891 15.122 1.68734 15.3776 1.96845C15.6332 2.24955 15.7671 2.62044 15.75 3V7.5" stroke={active ? "#fff" : "#8193A0"} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export { AnalyticsRender, UploadRender, LibraryRender, SettingsRender, NotificationRender, LogoutRender }