import React, { useState } from 'react';
import { Table, Tag, Space, Button, message, Spin, Row, Form, Col, Input, Select, Skeleton } from 'antd';
import musicIcon from '../../images/icons/musicIcon.svg'
import './Dashboard.css'
import api from '../../utils/api';
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons';
import { DashboardContext } from "./Dashboard";
import { UPDATE_LIBRARY, UPDATE_SERIES } from "../../utils/constants";
import { useHistory } from 'react-router';

import DeleteLight from './../../components/DeleteLight';
import { format } from 'date-fns';

const { Option } = Select;

const Library: React.FC = () => {

  const history = useHistory();
  const { state: dashboardState, dispatch }: any = React.useContext(DashboardContext);
  const [fetching, setFetching] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [selectedLibraryData, setSelectedLibaryData] = useState(null);
  const [isVisibleDeleteModal, setVisibleDeleteModal] = useState(false);

  React.useEffect(() => {
    document.title = "Lighthouse | Library";
    fetchLibraries();
  }, []);

   const fetchLibraries = async () => {
     setFetching(true);
     try {
       const { data } = await api.fetchLibrary();
       console.log("library", data);
       dispatch({ type: UPDATE_LIBRARY, payload: data });
     } catch (error) {
       message.error("opps, there seems to be an issue with your request");
     } finally {
       setFetching(false);
     }
   };

   const handleOpenDeleteModal = (record: any) => {
     setSelectedLibaryData(record);
     setVisibleDeleteModal(true);
   };

  const handleCloseDeleteModal = () => {
     setSelectedLibaryData(null);
     setVisibleDeleteModal(false);
   }

   const handleDeleteLight = async (item: any) => {
     setDeleting(true);
     try {
       const response = await api.deleteLight(item.key);
       onDeleteSuccess(item);
       handleCloseDeleteModal();
     } catch (error) {
       console.log(error);
       message.error("There was an issue with your request, kindly try again");
     } finally {
       setDeleting(false);
     }
   };

  const data =
    dashboardState.library.lights &&
    dashboardState.library.lights.map((lib: any) => {
      console.log(lib);
      return {
        ...lib,
        key: lib._id,
        // title: lib.title,
        // isPublished: lib.published,
        // category: lib.category,
        // mood: lib.mood
      };
    });

  const onDeleteSuccess = (record: any) => {
    const currentRecord = [...data];
    const recordIndexInData = currentRecord.findIndex(
      (item: any) => item.key === record.key
    );
    currentRecord.splice(recordIndexInData, 1);
    console.log(currentRecord);
    // setData(currentRecord)
    dispatch({type: UPDATE_LIBRARY, lights: currentRecord})
  };



  const editLight = (data: any) => {
    history.push("/dashboard/upload?edit=true", { state: data });
  };

  const Tag = ({children} : any) => (
    <span
      style={{
        backgroundColor: '#289DF3',
        padding: '4px 8px',
        borderRadius: 4,
        color: '#ffffff',
        fontSize: 11,
        margin: '2px 5px'
      }}
    >{children}</span>
  )

  const columns = [
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      render: (text: React.ReactNode, record: any) => (
        <div className="libraryTitleContainer">
          <div className="libraryTitleImage">
            <img src={musicIcon} alt="music" />
          </div>
          <div>
            <p className="libraryTitle">{record.title}</p>
            <p className="libraryCategoryAndMood">
              <Tag>{record.category}</Tag>
              <Tag>{record.mood} </Tag>
            </p>
            <p className="libraryEditedAt" style={{fontSize: 10}}>{record.updatedAt === record.createdAt ? `Published ${format(new Date(record.createdAt), 'EEE, do MMMM yyyy')} at ${format(new Date(record.createdAt), 'p')}` : `Last Edited ${format(new Date(record.updatedAt), 'EEE, do MMMM yyyy p')} at ${format(new Date(record.createdAt), 'p')}`}</p>
          </div>
        </div>
      ),
    },
    // {
    //   title: "isPublished",
    //   dataIndex: "isPublished",
    //   key: "isPublished",
    //   render: (text: any, record: any) => (
    //     <>
    //       {/* {record.isPublished
    //         ? <Tag className="libraryPublishedTag">Published</Tag>
    //          : <Tag className="libraryDraftTag">Draft</Tag>} */}
    //     </>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Space>
            <Button type="primary" onClick={() => editLight(record)}>Edit</Button>
            <Button type="primary" danger onClick={() => handleOpenDeleteModal(record)}>Delete</Button>
          </Space>
          </div>
      ),
    },
  ];


  return (
    <LibraryWrapper style={{ minWidth: "100%" }}>
      <h1>My Library</h1>
      <p className="tagline">
        Your library contains all your lights. Manage all your uploaded contents here.
      </p>
      <div>

      </div>
      {fetching && !Object.keys(dashboardState.library).length ? (
        <div>
          <Skeleton.Input style={{ width: '33.33%', height: 50}} active size='large' />
          <Skeleton.Input active size='large' />
          <Skeleton.Input active size='large' />
          <Skeleton.Input active size='large' />
        </div>
      ) : (
        <>
          <Row>
            <Col span={8} style={{marginBottom: 5}}>
              <Input
                prefix={<SearchOutlined className="site-form-item-icon" />}
                placeholder="Find a light by name" />
            </Col>
            {/* <Col span={8}>
              <div style={{display: 'flex'}}>
                <span>Sort By</span>
                <Select
                  showSearch
                  showArrow
                  placeholder="Country"
                  style={{ width: '100%' }}
                >
                  <Option value="last-updated"> Last Updated </Option>
                  <Option value="last-updated"> Last Updated </Option>
                </Select>
              </div>
            </Col> */}
          </Row>
          <Table columns={columns} dataSource={data} showHeader={false} />
        </>
      )}
      <DeleteLight data={selectedLibraryData || {}} visible={isVisibleDeleteModal} loading={deleting} onDeleteLight={handleDeleteLight} onClose={handleCloseDeleteModal} />
    </LibraryWrapper>
  );
}

export default Library;

const LibraryWrapper = styled.div`
  .ant-skeleton-element .ant-skeleton-input-lg {
    width: 90%;
    height: 100px;
    margin: 3px 0;
  }
  .ant-input-affix-wrapper {
    padding: 0;
    .ant-input-prefix {
      line-height: 48px;
      margin-left: 10px;
    }
    .ant-input {
      font-size: 14px;
      height: 48px;
      border-radius: 3px;
    }
  }
  .libraryTag {
    padding: 20px,
    background: 'blue'
  }
`;
