// export default Object.freeze({
//     baseUrl: "https://virtserver.swaggerhub.com/dirisujesse/lighthouse/1.0.0/",
// })

// Dev base url
// export const baseUrl: string = "https://us-central1-lighthouse-dev-dfa1a.cloudfunctions.net";

// Prod base url
export const baseUrl: string = "https://us-central1-lighthouse-7d374.cloudfunctions.net";


//action types
export const UPDATE_PROFILE: string = "UPDATE_PROFILE"
export const UPDATE_SERIES: string = "UPDATE_SERIES"
export const UPDATE_LIBRARY: string = "UPDATE_LIBRARY"
export const UPDATE_ANALYTICS: string = "UPDATE_ANALYTICS";
export const UPDATE_TIMELY_ANALYTICS: string = "UPDATE_TIMELY_ANALYTICS";
export const SET_CATEGORIES: string = "SET_CATEGORIES";
export const SET_MOODS: string = "SET_MOODS"

