import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/analytics'

import config from '../firebaseConfig'

firebase.initializeApp(config)

const storage = firebase.storage();

export {
  storage, firebase as default
}
