import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Divider,
  Form,
  Select,
  Upload,
  message,
  Switch,
  Progress,
} from "antd";

import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import AddSeriesModal from "../../components/AddSeriesModal/index";
import { storage } from "../../utils/firebase";
import api from "../../utils/api";
import { DashboardContext } from "./Dashboard";
import { UPDATE_SERIES, SET_CATEGORIES, SET_MOODS } from "../../utils/constants";

const { Dragger } = Upload;

interface Props {
  // series?: any;
  // updateSeries?: () => void
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const getFileNameFromUrl = (url: string) => {
  let imageNamePattern: any = url.split("%2F");
  imageNamePattern = imageNamePattern[imageNamePattern.length - 1].split(
    "?"
  )[0];
  return imageNamePattern;
};

const UploadPage: React.FC<Props> = (props) => {
  const history = useHistory();
  const query = useQuery();

  const { state: dashboardState, dispatch }: any = React.useContext(
    DashboardContext
  );
  const authState: any = dashboardState.profile;

  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [belongToASeries, setBelongToASeries] = useState(false);
  const [visibleNewSeriesModal, setVisibleNewSeriesModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fetchingOptions, setFetchingOptions] = useState(false);
  const [fetchingCategories, setFetchingCategories] = useState(false);

  //Upload light state
  const [uploadedLight, setuploadedLight]: any = useState([]);
  const [lightUploadProgess, setLightUploadProgress] = useState(0);
  const [uploadingLight, setUploadingLight] = useState(false);

  // Upload art state
  const [uploadedArt, setuploadedArt]: any = useState([]);
  const [artUploadProgess, setArttUploadProgress] = useState(0);
  const [uploadingArt, setUploadingArt] = useState(false);

  //EDIT DATA: query param and data passed from edit button on library page
  const hasQuery = query.get("edit");
  const editState: any = history.location.state;
  const hasEditData = editState && !!hasQuery;

  // Initialize upload form fields on Edit Light
  useEffect(() => {

    document.title = "Lighthouse | Upload";

    if (hasEditData) {
      form.setFieldsValue({
        ...editState.state,
        seriesId: editState.state.series,
      });
      setBelongToASeries(!!editState.state.series);
      setuploadedLight([
        {
          uid: "0",
          name: getFileNameFromUrl(editState.state.url),
          status: "done",
          url: editState.state.url,
        },
      ]);
      setuploadedArt([
        {
          uid: "0",
          name: getFileNameFromUrl(editState.state.image),
          status: "done",
          url: editState.state.image,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (dashboardState.series.length < 1) fetchSeries();
  }, []);

  const fetchSeries = async () => {
    setFetchingOptions(true);
    try {
      const [seriesResponse, categoriesResponse, moodsResponse] = await Promise.all([
        api.fetchSeries(),
        api.fetchCategories(),
        api.fetchMoods()
      ]);
      dispatch({ type: UPDATE_SERIES, payload: seriesResponse.data.series});
      dispatch({ type: SET_CATEGORIES, payload: categoriesResponse.data});
      dispatch({ type: SET_MOODS, payload: moodsResponse.data })
    } catch (error) {
      // setHasError(true)
      console.log(error);
    } finally {
      setFetchingOptions(false);
    }
  };

  // Must be in this format [{value: 'data}]
  // const messageCategories = [
  //   { value: "Work" },
  //   { value: "Marriage" },
  //   { value: "Parenting" },
  //   { value: "Leadership" },
  //   { value: "Worship" },
  // ];

  const moodCategories = dashboardState.moods && dashboardState.moods.map((m: any) => ({
    label: m.emoji + ' ' + m.name,
    value: m.name
  }));

  const seriesList = dashboardState.series && dashboardState.series.map((s: any) => ({
    label: s.title,
    value: s._id,
  }));

  const messageCategories = dashboardState.categories && dashboardState.categories.map((c: any) => ({
    label: c.name,
    value: c.name
  }));

  console.log(seriesList);

  const tagRender = ({ label, onClose }: any) => {
    return (
      <div className="tagRenderer">
        {label}
        <span onClick={onClose} style={{ cursor: "pointer" }}>
          <img src={require("./../../images/icons/close.svg")} />
        </span>
      </div>
    );
  };

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    const { seriesId, ...rest } = values;
    if (uploadedArt.length < 1 || uploadedLight < 1) {
      message.error("Kindly uploaded light and light art");
      return;
    }
    setSubmitting(true);
    let response;
    try {
      if (hasEditData) {
        response = await api.updateLight(editState.state._id, {
          ...values,
          image: uploadedArt[0].url,
          url: uploadedLight[0].url,
        });
      } else {
        response = await api.addLight(values.seriesId, {
          lights: [
            {
              ...rest,
              image: uploadedArt[0].url,
              url: uploadedLight[0].url,
              price: 0,
              duration: 0,
            },
          ],
        });
        form.resetFields();
        setuploadedArt([]);
        setuploadedLight([]);
      }
      message.success(response.data.message);
      history.push("/dashboard/library");
    } catch (error) {
      console.log(error);
      error.response
        ? message.error(error.response.data.error)
        : message.error("Profile update not successful");
    } finally {
      setSubmitting(false);
    }
  };

  const toggleSeriesSwitch = (checked: boolean) => {
    setBelongToASeries(checked);
  };

  const lightProps = {
    name: "file",
    multiple: false,
    onChange(info: any) {
      if (info.file.status === "removed") return;

      const isLt24M = info.file.size / 1024 / 1024 < 25;
      if (!isLt24M) {
        message.error("File must smaller than 25MB!");
        return isLt24M;
      }

      setUploadingLight(true);
      setuploadedLight([]);

      let filename = `light-audio-${Date.now()}`;
      if (uploadedLight.length > 0) {
        let imageName = getFileNameFromUrl(uploadedLight[0].url);
        filename = imageName;
      }

      const uploadFile = storage
        .ref(`${authState.user._id}/lights/${filename}`)
        .put(info.file.originFileObj);
      uploadFile.on(
        "state_changed",
        (snapshot) => {
          // progress
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setLightUploadProgress(progress);
        },
        (error) => {
          //error
          console.log(error);
          message.error(`Light upload failed.`);
        },
        () => {
          // complete
          storage
            .ref(`${authState.user._id}/lights`)
            .child(filename)
            .getDownloadURL()
            .then((url) => {
              setuploadedLight([{ ...info.file, status: "done", url }]);
            });
          message.success(`Light uploaded successfully.`);
          setUploadingLight(false);
        }
      );
    },
    onRemove: () => {
      setuploadedLight([]);
      setLightUploadProgress(0);
    },
    fileList: uploadedLight,
  };

  const artProps = {
    name: "file",
    multiple: false,
    onChange(info: any) {
      if (info.file.status === "removed") return;

      // Check file size before upload
      const isLt1M = info.file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        message.error("Image must smaller than 1MB!");
        return isLt1M;
      }



      let filename = `light-art-${Date.now()}`;
      if (uploadedArt.length > 0) {
        let imageNamePattern = uploadedArt[0].url.split("%2F");
        imageNamePattern = imageNamePattern[imageNamePattern.length - 1].split(
          "?"
        )[0];
        console.log(imageNamePattern);
        filename = imageNamePattern;
      }

      setUploadingArt(true);
      setuploadedArt([]);
      const uploadFile = storage
        .ref(`${authState.user._id}/arts/${filename}`)
        .put(info.file.originFileObj);
      uploadFile.on(
        "state_changed",
        (snapshot) => {
          // progress
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setArttUploadProgress(progress);
        },
        (error) => {
          //error
          console.log(error);
          message.error(`Light art upload failed.`);
        },
        () => {
          // complete
          storage
            .ref(`${authState.user._id}/arts`)
            .child(filename)
            .getDownloadURL()
            .then((url) => {
              setuploadedArt([{ ...info.file, status: "done", url }]);
            });
          message.success(`Light art uploaded successfully.`);
          setUploadingArt(false);
        }
      );
    },
    onRemove: () => {
      setuploadedArt([]);
      setArttUploadProgress(0);
    },
    fileList: uploadedArt,
  };

  return (
    <UploadWrapper>
      <h1>{hasEditData ? "Edit Upload" : "Upload Light"}</h1>
      {/* <p className="tagline">Fill the form below to upload your sermons and messages to be share with over 400 people.</p> */}

      <Form
        className="uploadForm"
        size="large"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row>
          <Col span={10}>
            <Form.Item
              name="title"
              label="Sermon Title"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Please input your sermon title!",
                },
              ]}
            >
              <Input placeholder="Your sermon title" />
            </Form.Item>

            <Form.Item
              name="description"
              label="Sermon Description"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Please enter sermon description!",
                },
              ]}
            >
              <TextArea placeholder="This sermon is about..." rows={4} />
            </Form.Item>

            <Form.Item
              name="category"
              label="Sermon Category"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Please input category!",
                },
              ]}
            >
              <Select
                // mode="multiple"
                tagRender={tagRender}
                loading={fetchingOptions}
                placeholder="This falls under"
                style={{ width: "100%" }}
                options={messageCategories}
                showArrow
              />
            </Form.Item>

            <Form.Item
              name="mood"
              label="Sermon Mood"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Please input mood!",
                },
              ]}
            >
              <Select
                // mode="tags"
                tagRender={tagRender}
                placeholder="Mood is"
                // defaultValue={["Sad", "Happy"]}
                style={{ width: "100%" }}
                options={moodCategories}
                loading={fetchingOptions}
                showArrow
              />
            </Form.Item>
            <p className="ant-upload-text">
              Does this sermon belong to a series?
              <Switch
                style={{ marginLeft: "11px" }}
                checked={belongToASeries}
                onChange={toggleSeriesSwitch}
              />
            </p>
            {belongToASeries && (
              <>
                {" "}
                <Form.Item
                  name="seriesId"
                  label="Pick Sermon Series"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: "Please input series!",
                    },
                  ]}
                >
                  <Select
                    // mode="tags"
                    tagRender={tagRender}
                    loading={fetchingOptions}
                    placeholder="Pick series"
                    // defaultValue={["Sad", "Happy"]}
                    style={{ width: "100%" }}
                    options={seriesList}
                    showArrow
                  />
                </Form.Item>
                <p
                  className="createNewSeries"
                  onClick={() => setVisibleNewSeriesModal(true)}
                >
                  Create new series
                </p>
              </>
            )}
            <AddSeriesModal
              visible={visibleNewSeriesModal}
              onClose={() => setVisibleNewSeriesModal(false)}
            />
          </Col>
          <Col span={3}></Col>
          <Col span={8}>
            <p className="ant-upload-text">Message Upload</p>
            <Dragger {...lightProps} accept="audio/*" className="uploadBox">
              <div className="row">
                <div className="cols">
                  <img src={require("./../../images/icons/DVD.svg")} />
                </div>
                <div className="col-2">
                  <p>
                    Provide FLAC, MP3, WAV, ALAC, or AIFF for highest audio quality
                  </p>
                </div>
                <div className="col-1">
                  <p>Max File Size 25MB</p>
                </div>
              </div>
              <div
                className="row justify-content-center"
                style={{ marginTop: "20px" }}
              >
                <img
                  style={{ marginRight: "8px" }}
                  src={require("./../../images/icons/file-arrow.svg")}
                />
                <div>
                  <h5>Drag and drop your sermon</h5>
                  <span>
                    or <span className="blueText">browse</span> to choose a file
                  </span>
                </div>
              </div>
            </Dragger>
            {uploadingLight && (
              <Progress
                percent={lightUploadProgess}
                size="small"
                status="active"
              />
            )}
            <p className="ant-upload-text" style={{ marginTop: "30px" }}>
              Sermon art Upload
            </p>
            <Dragger {...artProps} accept="image/*" className="uploadBox">
              <div className="row">
                <div className="cols">
                  <img src={require("./../../images/icons/Picture.svg")} />
                </div>
                <div className="col-2">
                  <p>Provide png, jpg, or bitmap for highest quality</p>
                </div>
                <div className="col-1">
                  <p>Max Image Size 1MB</p>
                </div>
              </div>
              <div
                className="row justify-content-center"
                style={{ marginTop: "20px" }}
              >
                <img
                  style={{ marginRight: "8px" }}
                  src={require("./../../images/icons/file-arrow.svg")}
                />
                <div>
                  <h5>Drag and drop your image</h5>
                  <span>
                    or <span style={{ color: "#289DF3" }}>browse</span> to
                    choose a file
                  </span>
                </div>
              </div>
            </Dragger>
            {uploadingArt && (
              <Progress
                percent={artUploadProgess}
                size="small"
                status="active"
              />
            )}
          </Col>
        </Row>
        <div style={{ flex: "1" }}></div>
        <Divider />
        <Form.Item className="bottomButtonsWrapper">
          {/* <Link to="#">I'll complete this later</Link> */}
          <Button
            type="primary"
            htmlType="submit"
            className="bottomCTA"
            block
            disabled={submitting}
          >
            {submitting
              ? "Processing..."
              : hasEditData
              ? "Edit Light"
              : "Upload Light"}
          </Button>
        </Form.Item>
      </Form>
    </UploadWrapper>
  );
};

// UploadPage.defaultProps = {}

export default UploadPage;

const UploadWrapper = styled.div`
  .ant-input {
    font-size: 14px;
    height: 49px;
    border-radius: 3px;
  }
  .ant-select .ant-select-selector {
    font-size: 14px;
    height: 49px;
    border-radius: 3px;
    padding: 0px 8px;
  }
  textarea.ant-input {
    height: 99px;
  }
  .ant-upload-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
  .ant-form-item-label {
    padding: 0px;
    padding-bottom: 8px;
    label {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
  }
  .ant-form-large .ant-form-item-label > label {
    height: auto;
  }
  .ant-switch-checked {
    background-color: #4cd964;
  }
  .tagRenderer {
    background-color: #eaf6fe;
    color: #34a9ff;
    height: 31px;
    font-size: 12px;
    padding: 5px 10px;
    margin-right: 10px;
    span {
      background: #34a9ff;
      margin-left: 16.27px;
      padding: 2px 6px 5px 6px;
      border-radius: 3px;
      img {
        width: 7.46px;
        height: 7.46px;
      }
    }
  }
`;
