import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import Dashboard from './pages/Dashboard/Dashboard';
import Onboarding from './pages/Onboarding/Onboarding';
import { Redirect } from 'react-router-dom';
import firebase from './utils/firebase';

function App() {
  // initialize firebase analytics
  firebase.analytics();

  return (
    <Router>
      <Switch>
        <Route path="/dashboard" component={Private(Dashboard)} />
        <Route path="/" component={Guest(Onboarding)} />
        <Route path="*" component={Guest(ErrorPage)} />
      </Switch>
    </Router>
  );
}

export default App;

const Guest = (WrappedComponent:React.ComponentType) => {
  const ACCESS_TOKEN = window.localStorage.getItem("_profile");
  return class extends Component {
    render() {
        return (!ACCESS_TOKEN) ? <WrappedComponent {...this.props } /> : <Redirect to="/dashboard/upload" />
    }
  };
}

const Private = (WrappedComponent:React.ComponentType) => {
  const ACCESS_TOKEN = window.localStorage.getItem("_profile");
  return class extends Component {
    render() {
        return (ACCESS_TOKEN) ? <WrappedComponent {...this.props } /> : <Redirect to="/" />
    }
  };
}
