import React from 'react';
import { Table, Avatar, message, Space, Spin } from 'antd';
import api from '../../utils/api';

const Subscribers: React.FC = () => {

  const [followersData, setFollowersData] = React.useState({ followers: [] });
   const [fetching, setFetching] = React.useState(false);

   React.useEffect(() => {
     fetchLibraries();
   }, []);

   const fetchLibraries = async () => {
     setFetching(true);
     try {
       const { data } = await api.fetchFollowers();
       console.log('followers', data)
       setFollowersData(data);
     } catch (error) {
       message.error("opps, there seems to be an issue with your request");
     } finally {
       setFetching(false);
     }
   };

  const columns = [
    {
      title: '',
      key: 'index',
      render: (text: any, record: any) => data.indexOf(record) + 1
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (text: any, record: any) => <Avatar size={50} src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />,
    },
    {
      title: 'Subscribers',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    }
  ];

  // const data = [
  //   {
  //     key: '1',
  //     name: 'John Brown',
  //     subscribers: 32,
  //     country: 'Nigeria',
  //   },
  //   {
  //     key: '2',
  //     name: 'Dan Brown',
  //     subscribers: 32,
  //     country: 'Brazil',
  //   },
  //   {
  //     key: '3',
  //     name: 'David Brown',
  //     subscribers: 32,
  //     country: 'New York No. 1 Lake Park',
  //   },
  //   {
  //     key: '4',
  //     name: 'Chidinma Brown',
  //     subscribers: 32,
  //     country: 'New York No. 1 Lake Park',
  //   },
  // ];

  const data: any = followersData.followers.map((f: any, i: number) => {
    return {
      key: i,
      name: f.name,
      subscribers: f.following,
      country: "N/A",
    };
  })

  return (
    <div style={{minHeight: '100%'}}>
      <h1>Subscribers</h1>
      {fetching ? (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 100}}>
          <Spin size="large" />
        </div>
      ) : (
          <Table columns={columns} dataSource={data} />
      )}
    </div>
  )
}

export default Subscribers;
