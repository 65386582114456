import React, { useState, useEffect } from "react";
import "./Onboarding.css";
import { Form, Input, Button, Alert, Row, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { Link, useHistory } from "react-router-dom";
import client from "../../utils/client";
import Axios from "axios";
import api from "../../utils/api";



const SignInPage: React.FC = () => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  useEffect(() => {
    document.title = "Lighthouse | Sign In";
  }, []);

  const onFinish = (values: any) => {
    setLoading(true);
    api.lightbearerLogin(values).then((response) => {
        // Login successful
        const user = response.data;
        window.localStorage.setItem('_profile', JSON.stringify(user));
        form.resetFields();
        setLoading(false);
        // history.push("/dashboard/upload");
        window.location.replace("/dashboard/analytics")
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Something went wrong , kindly try again";
        if (error.response) {
          errorMessage = error.response.data.error
        }
        setError(errorMessage)
      })
  };

  return (
    <div className="signInPage">
      <Row className='formFields' style={{justifyContent: 'center'}}>
        <Col span={10} style={{textAlign: 'center', marginBottom: '20px'}}>
          <img style={{marginBottom: '20px'}} src={require('./../../images/icons/onboarding-logo.svg')} />
        </Col>
      </Row>
      <div className="headers">
        <h1>Glad to have you back</h1>
        <p>To continue enter your email and password</p>
      </div>
      { error && <Alert style={{marginBottom: '30px'}} message={error} type="error" showIcon /> }
      <Form
        name="normal_login"
        form={form}
        onFinish={onFinish}
        size="large"
        layout="vertical"
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Your Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={loading}
            block
          >
            { loading ? "Logging in..." : "Log in" }
          </Button>
        </Form.Item>
        <Form.Item>
          <Link to="sign-up">Request an account!</Link>
          <br/>
          <Link to="/forget-password">Forgot password</Link>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignInPage
