import React from 'react';
import { NavLink, Route, useRouteMatch, Switch, Redirect, useHistory } from 'react-router-dom';

import Analytics from './Analytics';
import Settings from './Settings';
import Notifications from './Notifications';
import Logout from './Logout';
import Library from './Library';
import Upload from './Upload';
import Subscribers from './Subscribers';

import './Dashboard.scss'
import { Row, Avatar, Alert } from 'antd';
import styled from 'styled-components'
import { AnalyticsRender, UploadRender, SettingsRender, LibraryRender, LogoutRender } from './../../components/SvgIcons';
import reducer, { initialState } from '../../reducer';

const userAddImg = require('./../../images/icons/user-add.svg');

export const DashboardContext = React.createContext({});

const Dashboard = () => {

  const [state, dispatch] = React.useReducer(reducer, initialState);

  let { path, url } = useRouteMatch();
  const history = useHistory();

  const logoutUser = () => {
    window.localStorage.removeItem('_profile');
    window.location.replace('/');
  }

  const { profile, metrics }: any = state

  // change this to user image on api
  const logoImg = require("./../../images/icons/logo-transparent.svg");
  const currentFullPathUrl = window.location.pathname;

  const getFirstLettersOfName = (name: string) => {
    const nameArr = name.split(' ')
    return nameArr[1] ? nameArr[0][0] + nameArr[1][0] : nameArr[0].slice(0, 2).toUpperCase();
  }

  const defaultProfileImageUrl = 'https://firebasestorage.googleapis.com/v0/b/lighthouse-7d374.appspot.com/o/profile.png?alt=media&token=a5d19707-7cfe-4f12-b594-2770d8b50070g'

  return (
    <DashboardWrapper>
      {profile.user.picture === defaultProfileImageUrl && (
        <BannerWrapper>
          <Alert
            onClick={() => history.push("/dashboard/settings")}
            type="info"
            message="Your profile cannot be discovered as you do not have a profile image yet. Please, upload your profile image to get discovered."
            style={{ textAlign: "center" }}
          />
        </BannerWrapper>
      )}
      <SidebarWrapper>
        <div
          className="header col"
          style={{ marginTop: profile.user.picture === defaultProfileImageUrl ? 15 : 0 }}
        >
          {/* kindly add an orientation for the image here */}
          <div className="logo">
            <img src={logoImg} />
          </div>
          <div className="text-center row align-items-center">
            {profile.user.picture ? (
              <Avatar
                className="avatar"
                size={100}
                src={profile.user.picture}
              />
            ) : (
              <AvatarWrapper>
                {getFirstLettersOfName(profile.user.name)}
              </AvatarWrapper>
            )}
          </div>
          <h5>{profile.user.name}</h5>
          <span style={{ fontSize: 11 }}>{profile.user.ministry}</span>
          <div className="subscribers">
            <img src={userAddImg} style={{ marginRight: "5px" }} />
            <div>
              <span className="subscribers-count">
                {metrics.subscribers
                  ? metrics.subscribers
                  : profile.user.followers || 0}
              </span>
              <span style={{ fontSize: 11 }}>subscribers</span>
            </div>
          </div>
        </div>
        <div className="sidebar-content">
          <Row justify="center" gutter={0}>
            <div className="icon">
              <NavLink activeClassName="active" to={`${url}/analytics`}>
                <div className="innerIcon">
                  <AnalyticsRender
                    active={currentFullPathUrl === "/dashboard/analytics"}
                  />
                  <p>Analytics</p>
                </div>
              </NavLink>
            </div>
            <div className="icon">
              <NavLink activeClassName="active" to={`${url}/upload`}>
                <div className="innerIcon">
                  <UploadRender
                    active={currentFullPathUrl === "/dashboard/upload"}
                  />
                  <p>Upload</p>
                </div>
              </NavLink>
            </div>
          </Row>
          <Row justify="center" gutter={0}>
            <div className="icon">
              <NavLink activeClassName="active" to={`${url}/library`}>
                <div className="innerIcon">
                  <LibraryRender
                    active={currentFullPathUrl === "/dashboard/library"}
                  />
                  <p>My Library</p>
                </div>
              </NavLink>
            </div>
            <div className="icon">
              <NavLink activeClassName="active" to={`${url}/settings`}>
                <div className="innerIcon">
                  <SettingsRender
                    active={currentFullPathUrl === "/dashboard/settings"}
                  />
                  <p>Profile</p>
                </div>
              </NavLink>
            </div>
          </Row>
          <Row justify="center" gutter={0}>
            {/* <div className="icon" >
                <NavLink activeClassName='active' to={`${url}/notifications`}>
                  <div className="innerIcon">
                    <NotificationRender active={currentFullPathUrl === '/dashboard/notifications'}/>
                    <p>Notifications</p>
                  </div>
                </NavLink>
              </div> */}
            <div className="icon" onClick={logoutUser}>
              <div className="innerIcon">
                <LogoutRender
                  active={currentFullPathUrl === "/dashboard/logout"}
                />
                <p>Log Out</p>
              </div>
            </div>
          </Row>
        </div>
      </SidebarWrapper>
      <MainPageWrapper>
        <div className="header"></div>
        <div className="shadow"></div>
        <div className="dashboardContent">
          <DashboardContext.Provider value={{ state, dispatch }}>
            <Switch>
              <Route exact path={path}>
                <Redirect to={`${url}/upload`} />
              </Route>
              <Route path={`${path}/analytics`} component={Analytics} />
              <Route path={`${path}/upload`} component={Upload} />
              <Route path={`${path}/library`} component={Library} />
              <Route path={`${path}/settings`} component={Settings} />
              <Route path={`${path}/notifications`} component={Notifications} />
              <Route path={`${path}/subscribers`} component={Subscribers} />
              <Route path={`${path}/logout`} component={Logout} />
            </Switch>
          </DashboardContext.Provider>
        </div>
      </MainPageWrapper>
    </DashboardWrapper>
  );
}

export default Dashboard;

const DashboardWrapper = styled.div`
  display: flex;
  background: #f9f9f9;
  oveflow-y: none;
  max-height: 100vh;
`

const BannerWrapper = styled.div`
  background: #f9f9f9;
  position: fixed;
  width: 100vw;
  z-index: 999;
  cursor: pointer;
  margin: 0;
`

const SidebarWrapper = styled.div`
  height: 100vh;
  max-height: 100vh;
  width: 250px;
  position: fixed;
  background: #f9f9f9;
  .sidebar-content {
    background: #f9f9f9;
    padding-top: 35px;
    .icon {
      height: 105px;
      width: 63px;
      margin: 0px 20px;
      display: inline-flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .innerIcon {
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #8193A0;
    }
    .innerIcon > img {
      height: 24px;
      width: 24px;
    }
    .innerIcon > p {
      margin: 5px 0 0 0;
      font-size: 10px;
    }
    .active {
      // height: 67px;
      width: 63px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--background-primary);
      color:  #ffffff;
      border-radius: 10px;
      box-shadow: 0px 10px 20px rgba(218, 218, 218, 0.47);
    }
    .active > .innerIcon > p {
      color: white;
    }
    .active .innerIcon img {
      filter: grayscale(1);
    }
  }
`

const MainPageWrapper = styled.div`
  margin-left: 250px;
  overflow-y: scroll;
  height: 100%;
  background-color: #fbfbfb;
  width: 100%;
  .dashboardContent {
    margin-top: calc(93px - 332px);
    width: calc(100vw - 290px);
    position: absolute;
    // height: calc(100vh - 93px);
    padding: 40px;
    border-radius: 10px;
    background-color: #fbfbfb;
    // background-color: #333;
    z-index: 1;
  }
  .shadow {
    position: absolute;
    top: 93px;
    margin-top: 93px;
    bottom: 0px;
    width: calc(100vw - 290px);
    // height: calc(100vh - 93px);
    height: 100%;
    mix-blend-mode: multiply;
    box-shadow: rgb(242, 242, 242) 0px 14vh 10px 4px;
}
`

const AvatarWrapper = styled.div`
  background: #C00F5A;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 48px;
  line-height: 100px;
  letter-spacing: -0.03em;
  color: #FFFFFF;
`
