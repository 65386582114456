import React from 'react';
import {
    Modal,
    Button,
  } from 'antd';
import './../../styles/modal.scss';



type ModalProps = {
  data: object,
  visible: boolean,
  onClose: Function,
  onDeleteLight: Function,
  loading: boolean
}

const DeleteLight = ({  data, visible, onClose, onDeleteLight, loading }: ModalProps) => {
    return (
        <Modal
              title=""
              visible={visible}
              okText="Create Series"
              footer={null}
              // onOk={this.handleOk}
              onCancel={() => onClose()}
            >
              <div style={{textAlign: 'center', padding: '63px'}}>
                <img src={require('./../../images/icons/delete.svg')} />
                <h2 style={{marginTop: '46px'}}>Confirm Deletion</h2>
                <p>This file will be deleted and this action cannot be undone</p>
                <Button type="link" danger onClick={()=> onClose()}>Cancel</Button>
                <Button type="primary" loading={loading} disabled={loading} danger onClick={() => onDeleteLight(data)}>Delete</Button>
              </div>
            </Modal>
    )
}

export default DeleteLight;
