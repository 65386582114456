import React, { useState } from "react";
import { Form, Input, Button, Alert, Upload, message } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";

import "./Onboarding.css";
import client from "../../utils/client";
import api from "../../utils/api";

const { Dragger } = Upload;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SetUpPage: React.FC = (props: any) => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  let query = useQuery();


  const lightProps = {
    name: 'file',
    multiple: false,
    onChange(info: any) {
      if (info.file.status === 'removed') return;
    }
  };

  const onFinish = (values: any) => {
    // const { name, email, ministry, website } = props.location.state;
    const id = query.get('id') || '';
    const { password, confirmPassword } = values;
    const payload = { password };
    if (password !== confirmPassword) {
      message.error('password does not match');
      return;
    }

    setLoading(true);
    console.log('the id', id)
    api
      .registerAccount(payload, id)
      .then((response) => {
        // Signup successful
        const user  = response.data;
        window.localStorage.setItem("_profile", JSON.stringify(user));
        form.resetFields();
        setLoading(false);
        window.location.replace("/dashboard/settings");
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Something went wrong , kindly try again";
        if (error.response) {
          errorMessage = error.response.data.error;
        }
        setError(errorMessage);
      });
  }

  return (
    <div className="signInPage">
      <div className="headers" style={{textAlign: 'center'}}>
        <img style={{marginBottom: '30px'}} src={require('./../../images/icons/onboarding-logo.svg')} />
        <h1>Setup Account</h1>
        <p>Almost there! Kindly create a password for your account.</p>
      </div>
      { error && <Alert style={{marginBottom: '30px'}} message={error} type="error" showIcon /> }
      <Form
        name="normal_login"
        form={form}
        onFinish={onFinish}
        size="large"
      >

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              min: 8,
              message: "Password must be a minimum of 8 characters!",
            },
          ]}
        >
          <Input
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please input your confirmed Password!",
            },
          ]}
        >
          <Input
            type="password"
            placeholder="confirm password"
          />
        </Form.Item>
        {/* <Form.Item
          name="podcast"
          rules={[
            {
              required: true,
              message: "Please input your Podcast Name!",
            },
          ]}
        >
          <Input placeholder="Podcast Name" />
        </Form.Item>
        <Form.Item
          name="podcast description"
          rules={[
            {
              required: true,
              message: "Please include a short podcast description",
            },
          ]}>
          <TextArea
            placeholder="podcast description"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <Dragger {...lightProps} accept="audio/*" className="uploadBox" style={{marginBottom: '30px'}}>
              <div className="row justify-content-center" style={{marginTop: '20px'}}>
                <img style={{marginRight: '8px'}} src={require('./../../images/icons/file-arrow.svg')} />
                <div>
                  <h5>Drag and drop your message</h5>
                  <span>or <span className="blueText">browse</span> to choose a file</span>
                </div>
              </div>
            </Dragger> */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            block
            disabled={loading}
          >
            { loading ? "Loading..." : "Finish" }
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SetUpPage
