import client from "./client";

export default {
  // onboarding
  requestAccount: (payload: object) => client.post("/requestAccount", payload),

  registerAccount: (payload: object, id: string) => client.post(`/register?id=${id}`, payload),

  lightbearerLogin: (payload: object) => client.put(`/lightbearerLogin`, payload),

  requestPasswordReset: ({ email }: { email: string}) => client.put(`/initiatePasswordReset?collection=lightbearers&email=${email}`),

  // Light
  addLight: (seriesId: string, payload: object) =>
    client.post(`/addLights${seriesId ? "?series=" + seriesId : ""}`, payload),

  updateLight: (id: string, payload: object) =>
    client.put(`/modifyLight?action=update&id=${id}`, payload),

  deleteLight: (id: string) =>
    client.put(`/modifyLight?action=delete&id=${id}`),

  // Categories
  fetchCategories: () => client.get("/categories"),

  // Moods
  fetchMoods: () => client.get('/moods'),

  // Series
  createSeries: (payload: object) => client.post("/createSeries", payload),

  fetchSeries: () => client.get("/mySeries"),

  updateLightBearer: (payload: any) =>
    client.put("/updateLightbearer", payload),

  // Profile/Settings
  fetchLibrary: () => client.get("/library"),

  // Analytics
  fetchMetrics: () =>
    client.get(`/metrics`),

  fetchPopularity: ({ start, end }: { start: number; end: number }) =>
    client.get(`/popularity?from${start}&to=${end}`),

  fetchStreams: ({ start, end }: { start: number; end: number }) =>
    client.post(`/streams`, { from: start, to: end }),

  // Subscribers
  fetchFollowers: () => client.get("/followers"),
};
