import React from 'react';
import { Row, Col, Avatar, Input, Form, Divider, Button, Space, message, Select, Upload } from 'antd';
import styled from 'styled-components'
import countries from './../../utils/countries';
import { UPDATE_PROFILE } from '../../utils/constants';
import { DashboardContext } from './Dashboard';
import api from '../../utils/api';
import { CameraFilled } from "@ant-design/icons";
import { storage } from "../../utils/firebase";
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

const Settings: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);

  const { state: dashboardState, dispatch }: any = React.useContext(DashboardContext);
  const profile = dashboardState.profile;

  React.useEffect(() => {
    document.title = "Lighthouse | Profile";
    form.setFieldsValue({ ...profile.user });
  }, []);

  const onFinish = async (values: any, isUpload = false) => {
    isUpload ? setUploading(true) : setLoading(true);
    try {
      const response = await api.updateLightBearer({ ...values });
      !isUpload && message.success(response.data.message);

      // Update persisted _profile data after success
      const updatedData: any = {
        token: profile.token,
        user: { ...profile.user, ...values },
      };

      localStorage.setItem("_profile", JSON.stringify(updatedData));

      // Update state from context
      dispatch({
        type: UPDATE_PROFILE,
        payload: { ...profile, user: { ...profile.user, ...values } },
      });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : "There was an issue with your request, Kindly try again later";
      message.error(errorMessage);
    } finally {
      isUpload ? setUploading(false) : setLoading(false);

    }
  };

  const props = {
    name: 'file',
    showUploadList: false,
    onChange(info: any) {
      const isLt1M = info.file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        message.error("Image must smaller than 1MB!");
        setUploading(false);
        return isLt1M;
      }

      setUploading(true);
      const uploadFile = storage
        .ref(`${profile.user._id}/profile/profile-photo`)
        .put(info.file.originFileObj);
      uploadFile.on(
        "state_changed",
        (snapshot) => {
          // progress
        },
        (error) => {
          //error
          message.error(`Profile picture upload failed.`);
          setUploading(false)
        },
        () => {
          // complete
          storage
            .ref(`${profile.user._id}/profile`)
            .child('profile-photo')
            .getDownloadURL()
            .then(url => {
              onFinish({picture: url}, true);
            });
        })
      }
    }

  return (
    <SettingsWrapper>
      <h1>Profile</h1>
      <Form className="signUpForm" size="large" form={form} onFinish={onFinish}>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row>
            <Col span={8}>
              <h2>Upload your profile picture</h2>
              {/* <p>
                Choose a new avatar to be used across LightHouse. Let listeners
                see your face
              </p> */}
            </Col>
            <Col span={1}></Col>
            <Col span={8} className="centerProfilePic">
              {profile.user && profile.user.picture ? (
                <Avatar
                  className="avatar"
                  size={150}
                  src={profile.user && profile.user.picture}
                />
              ) : (
                <AvatarWrapper>JA</AvatarWrapper>
              )}
              <Upload {...props} accept="image/*">
                <Button
                  className="uploadImage"
                  loading={uploading}
                  disabled={uploading}
                >
                  <CameraFilled /> Update
                </Button>
              </Upload>
              {/* <Avatar className="avatar" size={150}  src={profile.user && profile.user.picture} /> */}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <h2>Personal Information</h2>
              <p>
                Please provide the correct information in the respective fields.
                The information provided her will be seen by your listeners.
              </p>
            </Col>
            <Col span={1}></Col>
            <Col span={8}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Name!",
                  },
                ]}
              >
                <Input placeholder="Your Name" />
              </Form.Item>
              <Form.Item
                name="bio"
                rules={[
                  {
                    required: true,
                    message: "Please enter your bio in 300 characters",
                  },
                ]}
              >
                <TextArea
                  placeholder="Your Bio in 300 characters"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  maxLength={300}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input placeholder="Your Email" />
              </Form.Item>
              <Form.Item
                name="ministry"
                rules={[
                  {
                    required: true,
                    message: "Please input your church!",
                  },
                ]}
              >
                <Input placeholder="Your church" />
              </Form.Item>
              <Form.Item
                name="website"
              >
                <Input placeholder="Your Website" />
              </Form.Item>
              <Form.Item
                name="country"
                required={false}
                rules={[
                  {
                    required: true,
                    message: "Please input county!",
                  },
                ]}
              >
                <Select
                  showSearch
                  showArrow
                  placeholder="Country"
                  style={{ width: "100%" }}
                >
                  {countries.map(({ emoji, name }, i) => {
                    return (
                      <Option value={name}>
                        {" "}
                        {emoji}&nbsp;&nbsp;{name}{" "}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please input your city!",
                  },
                ]}
              >
                <Input placeholder="City" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <h2>Social Media</h2>
              <p>We'd like to connect with you on Facebook and Twitter.</p>
            </Col>
            <Col span={1}></Col>
            <Col span={8}>
              <Form.Item
                name="facebook_profile"
                rules={[
                  {
                    required: true,
                    message: "Please input your facebook handle!",
                  },
                ]}
              >
                <Input placeholder="Facebook URL" />
              </Form.Item>
              <Form.Item
                name="twitter_handle"
                rules={[
                  {
                    required: true,
                    message: "Please input your twitter handle!",
                  },
                ]}
              >
                <Input placeholder="Twitter URL" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Form.Item className="bottomButtonsWrapper">
            <Button
              type="primary"
              htmlType="submit"
              className="bottomCTA"
              block
              disabled={loading}
            >
              {loading ? "Updating..." : "Update Account"}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </SettingsWrapper>
  );
}

export default Settings;

const SettingsWrapper = styled.div`
  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.03em;
    color: #000000;
    margin-bottom: 54px;
  }
  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 10px;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.7);
  }
  .ant-input {
    font-size: 14px;
    height: 46px;
    border-radius: 3px;
  }
  .ant-select .ant-select-selector {
    font-size: 14px;
    height: 46px !important;
    border-radius: 3px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 43px;
  }
  .uploadImage {
    padding: 3px 10px;
    height: auto;
  }
  .centerProfilePic {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    button {
      font-size: 13px;
    }
  }
`

const AvatarWrapper = styled.div`
  background: #C00F5A;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 48px;
  line-height: 150px;
  letter-spacing: -0.03em;
  color: #FFFFFF;
`
