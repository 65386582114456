import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Alert } from "antd";
import { Link } from "react-router-dom";

import "./Onboarding.css";
import api from "../../utils/api";

const ForgotPasswordPage: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState('');

  const onFinish = (values: any) => {
    setLoading(true);
    setError('')
    setSuccess('')

    api.requestPasswordReset(values).then((response) => {
        form.resetFields();
        setLoading(false);
        setSuccess(response.data.message)
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Something went wrong , kindly try again";
        if (error.response) {
          errorMessage = error.response.data.error;
        }
        setError(errorMessage);
      });
  };

  return (
    <div className="signInPage">
      <Row className='formFields' style={{justifyContent: 'center'}}>
        <Col span={10} style={{textAlign: 'center', marginBottom: '30px'}}>
          <img style={{marginBottom: '20px'}} src={require('./../../images/icons/onboarding-logo.svg')} />
        </Col>
      </Row>
      <div className="">
        <h1> Recover your Password</h1>
        <p>Enter your user account's verified email address and we will send you a password reset link</p>
      </div>
      { error && <Alert style={{marginBottom: '30px'}} message={error} type="error" showIcon /> }
      { success && <Alert style={{marginBottom: '30px'}} message={success} type="success" showIcon /> }
      <Form
        name="normal_login"
        form={form}
        onFinish={onFinish}
        size="large"
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input placeholder="Your Email" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            block
            disabled={loading}
          >
            { loading ? "Loading..." : "Send Password reset email" }
          </Button>
        </Form.Item>
        <Form.Item>
          <Link to="sign-up">Request an account!</Link>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ForgotPasswordPage;
