import React, { useState } from "react";
import { Form, Input, Button, Alert, message } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import "./Onboarding.css";
import axios from "axios";
import { baseUrl } from "../../utils/constants";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword: React.FC = (props: any) => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  let query = useQuery();


  const onFinish = (values: any) => {
    // const { name, email, ministry, website } = props.location.state;
    const token = query.get('token') || '';
    const { password, confirmPassword } = values;
    const payload = { password };
    if (password !== confirmPassword) {
      message.error('password does not match');
      return;
    }

    setLoading(true);
    console.log('the token', token)

    const config = {
      headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    let $axios = axios.create({
      ...config,
      baseURL: baseUrl,
    });

   $axios
     .put("/completePasswordReset", payload).then((response) => {
       // Signup successful
       // const user  = response.data;
       form.resetFields();
       setLoading(false);
       setSuccess(response.data.message);
     })
     .catch((error) => {
       setLoading(false);
       let errorMessage = "Something went wrong, kindly try again";
       if (error.response) {
         errorMessage = error.response.data.error;
       }
       setError(errorMessage);
     });
  }

  return (
    <div className="signInPage">
      <div className="headers" style={{textAlign: 'center'}}>
        <img style={{marginBottom: '30px'}} src={require('./../../images/icons/onboarding-logo.svg')} />
        <h1>Complete Password Reset</h1>
        <p>Almost there! Kindly enter a new password for your account.</p>
      </div>
      { error && <Alert style={{marginBottom: '30px'}} message={error} type="error" showIcon /> }
      { success && <Alert style={{marginBottom: '30px'}} message={success} type="success" showIcon /> }
      <Form
        name="normal_login"
        form={form}
        onFinish={onFinish}
        size="large"
      >

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              min: 8,
              message: "Password must be a minimum of 8 characters!",
            },
          ]}
        >
          <Input
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please input your confirmed Password!",
            },
          ]}
        >
          <Input
            type="password"
            placeholder="confirm password"
          />
        </Form.Item>
        {/* <Form.Item
          name="podcast"
          rules={[
            {
              required: true,
              message: "Please input your Podcast Name!",
            },
          ]}
        >
          <Input placeholder="Podcast Name" />
        </Form.Item>
        <Form.Item
          name="podcast description"
          rules={[
            {
              required: true,
              message: "Please include a short podcast description",
            },
          ]}>
          <TextArea
            placeholder="podcast description"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <Dragger {...lightProps} accept="audio/*" className="uploadBox" style={{marginBottom: '30px'}}>
              <div className="row justify-content-center" style={{marginTop: '20px'}}>
                <img style={{marginRight: '8px'}} src={require('./../../images/icons/file-arrow.svg')} />
                <div>
                  <h5>Drag and drop your message</h5>
                  <span>or <span className="blueText">browse</span> to choose a file</span>
                </div>
              </div>
            </Dragger> */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            block
            disabled={loading}
          >
            { loading ? "Loading..." : "Reset Password" }
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPassword
