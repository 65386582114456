import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Table,
  Space,
  Select,
  Skeleton,
} from 'antd';

// import { VictoryPie, VictoryChart, VictoryArea, VictoryLabel } from 'victory';
import Chart from 'react-apexcharts'
import styled from 'styled-components'
import { LoadingOutlined } from "@ant-design/icons";

import totalHoursStreamed from '../../images/icons/totalHoursStreamed.svg'
import totalStreamingUsers from '../../images/icons/totalStreamingUsers.svg'
import totalPublishedLights from '../../images/icons/totalPublishedLights.svg'


import './Dashboard.css'
import { DashboardContext } from './Dashboard';
import { UPDATE_ANALYTICS, UPDATE_TIMELY_ANALYTICS } from '../../utils/constants';
import api from '../../utils/api';
import { startOfWeek } from 'date-fns'
import { endOfWeek, subDays, startOfMonth, endOfMonth, format } from 'date-fns/esm';

const { Option } = Select;


const Analytics: React.FC = () => {

  const thisWeek = {
    start: () => startOfWeek(new Date()).getTime(),
    end: () => endOfWeek(new Date()).getTime(),
  }

  const lastWeek = {
    start: () => startOfWeek(subDays(thisWeek.start(), 1)).getTime(),
    end: () => endOfWeek(subDays(thisWeek.start(), 1)).getTime()
  }

  const thisMonth = {
    start: () => startOfMonth(new Date()).getTime(),
    end: () => endOfMonth(new Date()).getTime(),
  };

  console.log('end of this month ', endOfMonth(new Date()).getTime())

  const lastMonth = {
    start: () => startOfMonth(subDays(thisMonth.start(), 1)).getTime(),
    end: () => endOfMonth(subDays(thisMonth.start(), 1)).getTime(),
  };

  const { state: dashboardState, dispatch }: any = React.useContext(
    DashboardContext
  );

  const [fetching, setFetching] = useState(false);

  const [timeRange, setTimeRange] = useState(
    JSON.stringify({
      start: thisWeek.start(),
      end: thisWeek.end(),
      key: 'thisWeek'
    })
  );

  const timeRangeObject = JSON.parse(timeRange);

  useEffect(() => {
    document.title = "Lighthouse | Analytics";
    fetchAnalyticsData(timeRangeObject.start, timeRangeObject.end, timeRangeObject.key);
  }, [timeRange]);
  const { fetchMetrics, fetchPopularity, fetchStreams, fetchLibrary } = api;

  const saveStreamsAndPopularityLocally = (data: {key: string, popularity: object, streams: object}) => {
    const storedAnalytics = JSON.parse(sessionStorage.getItem("lighthouse_analytics") || "{}");

    const payload = {
      ...storedAnalytics,
      timeStamp: storedAnalytics.timeStamp || Date.now(),
      [data.key]: data,
    }
    sessionStorage.setItem('lighthouse_analytics', JSON.stringify(payload));
  };

  const fetchAnalyticsData = async (start: number, end: number, key: string) => {
    setFetching(true);

    try {
      if (!Object.keys(metrics).length) {

        const [metricsRes, popularityRes, streamsRes, libraryRes]: any = await Promise.all([
          fetchMetrics(),
          fetchPopularity({ start, end }),
          fetchStreams({ start, end }),
          fetchLibrary(),
        ]);

        dispatch({
          type: UPDATE_ANALYTICS,
          payload: {
            metrics: metricsRes.data,
            popularity: popularityRes.data,
            streams: streamsRes.data,
            libraries: libraryRes.data,
          },
        });
        saveStreamsAndPopularityLocally({ popularity: popularityRes.data, streams: streamsRes.data, key })
      } else {
        const storedAnalytics = JSON.parse(sessionStorage.getItem("lighthouse_analytics") || "{}");
        const dataFetched = Object.keys(storedAnalytics).find(k => k === key);
        const itsBeenAnHour = (Date.now() - storedAnalytics.timeStamp) >= 3600000;

        if(itsBeenAnHour) {
          sessionStorage.removeItem('lighthouse_analytics');
        }

        if (!itsBeenAnHour){
          if (!!dataFetched) {
            const data = storedAnalytics[dataFetched];
            dispatch({
              type: UPDATE_TIMELY_ANALYTICS,
              payload: {
                popularity: data.popularity,
                streams: data.streams,
              },
            });
            return
          }
        }

         const [
           popularityRes,
           streamsRes,
         ]: any = await Promise.all([
           fetchPopularity({ start, end }),
           fetchStreams({ start, end }),
         ]);

         dispatch({
           type: UPDATE_TIMELY_ANALYTICS,
           payload: {
             popularity: popularityRes.data,
             streams: streamsRes.data,
           },
         });
         saveStreamsAndPopularityLocally({ popularity: popularityRes.data, streams: streamsRes.data, key })
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  const { metrics, library, popularity, streams } = dashboardState;

  const labels = popularity.categoryPie &&
    Object.keys(popularity.categoryPie)
      .filter((item)=>popularity.categoryPie[item].streams)
      .map((l)=> l[0].toUpperCase()+l.slice(1));

  const donutSeries = popularity.categoryPie && Object.keys(popularity.categoryPie).length ? labels.map((l) => {
    return popularity.categoryPie[l.toLowerCase()].streams;
  }) : [];

  const colors = ['#01058A', '#34A9FF', '#FFAE34', '#7F0BC7', '#E45477', '#535489', '#DE5134', '#5EB7B7', '#9C5959', '#7E90D5', '#C66775', '#B7928E', '#657761', '#A670A4', '#FADBE9'];

  const daysInInterval = Object.keys(streams).map(s=>s);
  const periods = daysInInterval.sort().map(d => {
    // const dateChunks = d.split('/')
    // const date = `${dateChunks[1]}/${dateChunks[0]}/${dateChunks[2]}`;
    // return format(new Date(d), 'MMM dd')
    const dateAsNumber = Number(d)
    return format(new Date(dateAsNumber), 'MMM dd')
  })

  const listenersData = daysInInterval.map(data => {
    return streams[data].listeners;
  })

  const streamsData = daysInInterval.map(data => {
    return streams[data].streams;
  })

  const columns = [
    {
      title: '',
      key: 'index',
      render: (text: any, record: any) => data.indexOf(record) + 1
    },
    {
      title: 'Lights',
      dataIndex: 'light',
      key: 'light',
    },{
      title: 'Hearts',
      dataIndex: 'noOfHearts',
      key: 'noOfHearts',
    },
    {
      title: 'Streams',
      dataIndex: 'noOfStreams',
      key: 'noOfStreams',
    },
    {
      title: 'Likes',
      dataIndex: 'noOfLikes',
      key: 'noOfLikes',
    },

    {
      title: 'Favourites',
      dataIndex: 'noOfFavourites',
      key: 'noOfFavourites',
    }
  ];

  const data =
    library.lights &&
    library.lights.map(
      (light: {
        title: string;
        likes: number;
        streams: number;
        favourites: string;
        hearts: number
      }) => ({
        light: light.title,
        noOfHearts: light.hearts,
        noOfStreams: light.streams,
        noOfLikes: light.likes,
        noOfFavourites: light.favourites,
      })
    );

  const donutOptions = {
    labels,
    colors,
    legend: {
      show: false,
    },
    stroke: {
      width: 0
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%'
        }
      }
    }
  }

  const areaOptions = {
    chart: {
        id: 'apexchart-example',
        background: '#fff',
        stacked: true,
        toolbar: {
            show: false
        }
    },
    colors: ["#00E396", "#289DF3"],
    markers: {
      colors: ["#00E396", "#289DF3"]
    },
    fill: {
      colors: ["#00E396", "#289DF3"]
    },
    dataLabels: {
        enabled: false
    },
    xaxis: {
        categories: periods,
        labels: {
            minHeight: 30,
            style: {
                cssClass: 'apexcharts-xaxis-label'
            }
        }
    },
  yaxis: {

    labels: {
        style: {
          height: '400'
        },
      }
    },
    // fill: {
    //     type: 'solid',
    //     colors: ["#e4f2fd"],
    // },
    stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: ["#00E396", "#289DF3"],
        width: 3
    },
    grid: {
        borderColor: '#DDDEE7',
        position: 'back',
        yaxis: {
          lines: {
            show: false
          },
        },
        xaxis: {
            lines: {
              show: false
            }
        },
        row: {
            colors: undefined,
            opacity: 0.5
        },
        column: {
            colors: undefined,
            opacity: 0.5
        },
    },
    legend: {
        show: true,
        colors: ["#00E396", "#289DF3"],
        position: 'bottom',
        horizontalAlign: 'right',
        height: '100px',
        offsetY: 5,
        markers: {
            width: 12,
            height: 12,
            strokeWidth: 3,
            strokeColor: '#fff',
            fillColors: ["#00E396", "#289DF3"],
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
        }
    }
  }



   const RenderLoader = () => {
     return (
       <>
         <Row gutter={16}>
           <Col span={6}>
             <Skeleton.Input active size="large" style={{ height: 110 }} />
           </Col>
           <Col span={6}>
             <Skeleton.Input active size="large" style={{ height: 110 }} />
           </Col>
           <Col span={6}>
             <Skeleton.Input active size="large" style={{ height: 110 }} />
           </Col>
           <Col span={6}>
             <Skeleton.Input active size="large" style={{ height: 110 }} />
           </Col>
         </Row>
         <Row gutter={16}>
           <Col span={12}>
             <Skeleton.Input active size="large" style={{ height: 240 }} />
           </Col>
           <Col span={12}>
             <Skeleton.Input active size="large" style={{ height: 240 }} />
           </Col>
         </Row>
         <div>
           <Skeleton.Input active size="large" />
           <Skeleton.Input active size="large" />
           <Skeleton.Input active size="large" />
         </div>
       </>
     );
   };


  return (
    <AnalyticsWrapper>
      <h1>Analytics</h1>
      <p className="tagline">
        Analytics provide insights on your uploaded lights and corresponding
        streams.
      </p>
      {fetching && !Object.keys(metrics).length ? (
        RenderLoader()
      ) : (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row gutter={16}>
            <Col span={6}>
              <Card size="small">
                <div className="analyticsPageStreamSummaryCard">
                  <img src={totalHoursStreamed} alt="total Hours Streamed" />
                  <div className="analyticsStreamSummary">
                    <p>Total Hearts Subscribed</p>
                    <p className="analyticsStreamSummaryData">
                      {metrics.subscribers}
                    </p>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card size="small">
                <div className="analyticsPageStreamSummaryCard">
                  <img src={totalStreamingUsers} alt="total Streaming Hearts" />
                  <div className="analyticsStreamSummary">
                    <p>Total Streaming Hearts</p>
                    <p className="analyticsStreamSummaryData">
                      {metrics.streamers}
                    </p>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card size="small">
                <div className="analyticsPageStreamSummaryCard">
                  <img
                    src={totalPublishedLights}
                    alt="total Published Lights"
                  />
                  <div className="analyticsStreamSummary">
                    <p>Total Published Lights</p>
                    <p className="analyticsStreamSummaryData">
                      {metrics.publishedLights}
                    </p>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card size="small">
                <div className="analyticsPageStreamSummaryCard">
                  <img src={totalHoursStreamed} alt="total Published Lights" />
                  <div className="analyticsStreamSummary">
                    <p>Total Hours Streamed</p>
                    <p className="analyticsStreamSummaryData">
                      {parseInt(metrics.streamHours)}
                    </p>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={16} className="col-container">
            <Col span={12} className="col-eq">
              <CardWrapper type="donut">
                <div className="card-title">Most Popular Category</div>
                {!donutSeries.length ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    No Data to display
                  </div>
                ) : (
                  <div
                    className="card-content"
                    style={{
                      width: '100%',
                      height: '100%',
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      padding: "0px 20px 0px 0px",
                      // marginTop: -10,
                    }}
                  >
                    <Chart
                      options={donutOptions}
                      series={donutSeries}
                      type="donut"
                      width="300"
                    />
                    <div style={{marginTop: 20}} >
                      {labels &&
                        labels.map((label, index) => (
                          <div className="analyticsCategoryLegend" key={label}>
                            <div
                              className="analyticsCategoryLegendColor"
                              style={{ backgroundColor: colors[index] }}
                            />
                            <p style={{textTransform: 'capitalize'}}>{label}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </CardWrapper>
            </Col>
            <Col span={12} className="col-eq">
              <CardWrapper type="area">
                <div className="card-title">
                  Daily Streams and Listener Analytics
                  {fetching && (
                    <LoadingOutlined style={{ fontSize: 24 }} spin />
                  )}
                  <Select
                    value={timeRange}
                    onChange={(value) => setTimeRange(value)}
                    style={{ width: 120 }}
                  >
                    <Option
                      value={JSON.stringify({
                        start: thisWeek.start(),
                        end: thisWeek.end(),
                        key: "thisWeek",
                      })}
                    >
                      This Week
                    </Option>
                    <Option
                      value={JSON.stringify({
                        start: lastWeek.start(),
                        end: lastWeek.end(),
                        key: "lastWeek",
                      })}
                    >
                      Last Week
                    </Option>
                    <Option
                      value={JSON.stringify({
                        start: thisMonth.start(),
                        end: thisMonth.end(),
                        key: "thisMonth",
                      })}
                    >
                      This Month
                    </Option>
                    <Option
                      value={JSON.stringify({
                        start: lastMonth.start(),
                        end: lastMonth.end(),
                        key: "lastMonth",
                      })}
                    >
                      Last Month
                    </Option>
                  </Select>
                </div>
                <div className="card-content">
                  <Chart
                    options={areaOptions}
                    series={[
                      {
                        name: "Listeners",
                        data: listenersData,
                      },
                      {
                        name: "Streams",
                        data: streamsData
                      }
                    ]}
                    type="area"
                    width="100%"
                    height={340}
                  />
                </div>
              </CardWrapper>
            </Col>
          </Row>
          <Table columns={columns} dataSource={data} />
        </Space>
      )}
    </AnalyticsWrapper>
  );
}

export default Analytics;


interface ButtonProps {
  type: string;
}

const AnalyticsWrapper = styled.div`
  .ant-skeleton-element .ant-skeleton-input-lg {
    margin: 8px 0;
  }
`;

const CardWrapper = styled.div<ButtonProps>`
  background: #fefefe;
  border: 0.5px solid #DFE2E6;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .card-title {
    display: flex;
    justify-content: space-between;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 19px 20px;
    background: #F8FAFB;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.03em;
    color: #111111;
    .ant-select {
      width: auto !important;
      // border: 0.5px solid #DFE2E6;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      .ant-select-selector {
        height: 36px !important;
        padding: 0 20px !important;
        .ant-select-selection-item {
          line-height: 34px !important;
        }
      }
    }
  }
  .card-content {
    padding: ${(props) => props.type === 'donut' ? '30px' : '10px' };
  }
  .col-container {
    display: flex;
    width: 100%;
  }
  .col-eq {
    flex: 1;
  }
`
