import React from 'react';
import {
    Modal,
    Button,
  } from 'antd';
import './../../styles/modal.scss';



type ModalProps = {
  visible: boolean,
  onClose: Function,
}

const AccountRequestModal = ({ visible, onClose }: ModalProps) => {
    return (
      <Modal
        title=""
        visible={visible}
        okText="Create Series"
        footer={null}
        // onOk={this.handleOk}
        onCancel={() => onClose()}
      >
        <div style={{ textAlign: "center", padding: "63px" }}>
          <img src={require("./../../images/icons/request-success.svg")} />
          <h2 style={{ marginTop: "46px" }}>Request Sent!</h2>
          <p>
            Your request has been sent. We will get back to you in 48 hours.
          </p>
          <br />
          <p>
            <img src={require("./../../images/icons/logo.svg")} style={{marginRight: 10}} />
            <a href="thelighthouse.app" style={{ textDecoration: "underline" }}>
              thelighthouse.app
            </a>
          </p>
        </div>
      </Modal>
    );
}

export default AccountRequestModal;
