import { UPDATE_PROFILE, UPDATE_SERIES, UPDATE_LIBRARY, UPDATE_ANALYTICS, UPDATE_TIMELY_ANALYTICS, SET_CATEGORIES, SET_MOODS } from "../utils/constants";

export const initialState = {
  series: [],
  profile: JSON.parse(localStorage.getItem("_profile") || "{}"),
  library: {},
  metrics: {},
  popularity: {},
  streams: {},
  categories: [],
  moods: []
};

const reducer = (state: any, action: {type: string, payload: any, lights: Array<any>}) => {
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: action.payload
      };
    case UPDATE_SERIES:
      return {
        ...state,
        series: action.payload
      };
    case UPDATE_LIBRARY:
      return {
        ...state,
        library: action.payload || {...state.library, lights: action.lights}
      };
    case UPDATE_ANALYTICS:
      return {
        ...state,
        metrics: action.payload.metrics,
        popularity: action.payload.popularity,
        streams: action.payload.streams,
        library: action.payload.libraries,
      };
    case UPDATE_TIMELY_ANALYTICS:
      return {
        ...state,
        popularity: action.payload.popularity,
        streams: action.payload.streams,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      }
    case SET_MOODS:
      return {
        ...state,
        moods: action.payload
      }
    default:
      return state;
  }
};

export default reducer
