import React from "react";
import { Form, Input, Button, Row, Col, Typography, Divider, Select, DatePicker, message } from "antd";
import { Link, useHistory, useRouteMatch, useLocation } from "react-router-dom";

import "./Onboarding.css";
import countries from "../../utils/countries";
import api from "../../utils/api";
import AccountRequestModal from "../../components/onboarding/AccountRequestModal";

// const { Title } = Typography;
const { Option } = Select;

const SignUpPage: React.FC = () => {
  const [form] = Form.useForm();
  // let history = useHistory()
  // let { url } = useRouteMatch();
  const [submitting, setSubmitting] = React.useState(false)
  const [success, setSuccess] = React.useState(false)

  React.useEffect(() => {
    document.title = "Lighthouse | Sign Up";
  }, []);

  const onChange = (date: any, dateString: string) => {
    console.log(date, dateString)
  }

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    try {
      setSubmitting(true)
      const response = await api.requestAccount(values);
      setSuccess(true);
      form.resetFields();
    } catch (error) {
      let errorMessage = 'Request Error, try again'
      if(error.response){
        errorMessage = error.response.data.error
      }
      message.error(errorMessage)
    } finally {
      setSubmitting(false)
    }

    // history.push({
    //   pathname: `${url}/setup`,
    //   state: values
    // })
  };

  const getMaxDob = () => {
    const thisYear = new Date().getFullYear();
    const maxYear = thisYear;
    return `${maxYear}-01-01`;
  }

  const maxDob = getMaxDob()

  return (
    <div>
      <Row className='formFields' style={{justifyContent: 'center'}}>
        <Col span={10} style={{textAlign: 'center', marginBottom: '30px', marginTop: 20}}>
          <img style={{marginBottom: '30px'}} src={require('./../../images/icons/onboarding-logo.svg')} />
          <h2>Request an account</h2>
          <p>Hi there! Welcome, we are pleased to have you on the Lighthouse service. Please fill out your details in this form and our team will reach out to you on how to proceed with your account set up in 48 hours. </p>
        </Col>
      </Row>
      <Form
        className="signUpForm"
        size="large"
        form={form}
        onFinish={onFinish}
      >
        <Row className='formFields'>
          <Col span={10}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your Name!",
                },
              ]}
            >
              <Input placeholder="Your Name" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input placeholder="Your Email" />
            </Form.Item>
            <Form.Item
              name="ministry"
              rules={[
                {
                  required: true,
                  message: "Please input your church!",
                },
              ]}
            >
              <Input placeholder="Your church" />
            </Form.Item>
            <Form.Item
              name="website"
            >
              <Input placeholder="Your website" />
            </Form.Item>
            <Form.Item
                name="gender"
                required={false}
                rules={[
                  {
                    required: true,
                    message: "Please input your gender!",
                  },
                ]}
              >
                <Select
                  showArrow
                  placeholder="Gender"
                  style={{ width: '100%' }}
                >
                  <Option value="female"> Female</Option>
                  <Option value="male"> Male</Option>
                </Select>
              </Form.Item>
            <Form.Item
                name="country"
                required={false}
                rules={[
                  {
                    required: true,
                    message: "Please input county!",
                  },
                ]}
              >
                <Select
                  showSearch
                  showArrow
                  placeholder="Country"
                  style={{ width: '100%' }}
                >
                  {countries.map(({emoji, name}, i) => {
                    return <Option value={name}> {emoji}&nbsp;&nbsp;{name} </Option>
                  })}
                </Select>
              </Form.Item>
          </Col>
          <Col span={4}></Col>
          <Col span={10}>
            <Form.Item
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please input your city!",
                },
              ]}
            >
              <Input placeholder="City" />
            </Form.Item>
            <Form.Item
              name="dob"
              rules={[
                {
                  required: true,
                  message: "Please input your DOB!",
                },
              ]}
            >
              <DatePicker placeholder="Date of Birth" onChange={onChange} style={{width: '100%'}} disabledDate={d => !d || d.isAfter(maxDob) }/>
            </Form.Item>
            <Form.Item
              name="facebook_profile"
            >
              <Input placeholder="Facebook Handle" />
            </Form.Item>
            <Form.Item
              name="twitter_handle"
            >
              <Input placeholder="Twitter Handle" />
            </Form.Item>
          </Col>
          <Divider />
            <Col span={24} style={{textAlign: 'right'}}>
            <Form.Item className="signUpBottom" style={{display: 'flex'}}>
              I have an account? <Link to="/">Sign In</Link>
              <Button
                type="primary"
                htmlType="submit"
                className="createAccountBtn"
                loading={submitting}
                disabled={submitting}
                style={{marginLeft: '20px'}}
              >
                {submitting ? 'Requesting account...' : 'Request an account'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <AccountRequestModal visible={success} onClose={() => setSuccess(false)}/>
    </div>
  );
};

export default SignUpPage
