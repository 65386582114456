import axios from 'axios';
import { baseUrl } from './constants';

const state = JSON.parse(localStorage.getItem("_profile") || "{}");

const config = {
    headers: {
        Accept: 'application/json',
        ContentType: 'application/json',
        Authorization: 'Bearer '+ state.token
    }
};

let client = axios.create({
    ...config,
    baseURL: baseUrl
});

export default client;
