import React, { useState } from "react";
import { Input, Form, Modal, Upload, Button, message, Progress } from "antd";
import "./../../styles/modal.scss";
import { InboxOutlined } from "@ant-design/icons";
import { storage } from "../../utils/firebase";
import api from "../../utils/api";
import { DashboardContext } from "../../pages/Dashboard/Dashboard";
import { UPDATE_SERIES } from "../../utils/constants";

const { TextArea } = Input;
const { Dragger } = Upload;

type ModalProps = {
  visible: boolean;
  onClose: Function;
};

// const props = {
//   name: 'file',
//   multiple: true,
//   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
//   onChange(info: any) {
//     const { status } = info.file;
//     if (status !== 'uploading') {
//       console.log(info.file, info.fileList);
//     }
//     if (status === 'done') {
//       message.success(`${info.file.name} file uploaded successfully.`);
//     } else if (status === 'error') {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
// };

const AddSeriesModal = ({ visible, onClose }: ModalProps) => {
  const { state: dashboardState, dispatch }: any = React.useContext(
    DashboardContext
  );
  const authState: any = dashboardState.profile;

  const [form] = Form.useForm();
  const [uploadedImage, setuploadedImage]: any = useState([]);
  const [imageUploadProgess, setImageUploadProgress] = useState(0);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  console.log(authState);

  const props = {
    name: "file",
    multiple: false,
    onChange(info: any) {
      if (info.file.status === "removed") return;

      const isLt1M = info.file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        message.error("Image must smaller than 1MB!");
        return isLt1M;
      }

      setUploadingImage(true);
      setuploadedImage([]);

      let filename = `light-series-${Date.now()}`;
      if (uploadedImage.length > 0) {
        let imageNamePattern = uploadedImage[0].url.split("%2F");
        imageNamePattern = imageNamePattern[imageNamePattern.length - 1].split(
          "?"
        )[0];
        console.log(imageNamePattern);
        filename = imageNamePattern;
      }

      const uploadFile = storage
        .ref(`${authState.user._id}/series/${filename}`)
        .put(info.file.originFileObj);
      uploadFile.on(
        "state_changed",
        (snapshot) => {
          // progress
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setImageUploadProgress(progress);
        },
        (error) => {
          //error
          console.log(error);
          message.error(`${info.file.name} file upload failed.`);
        },
        () => {
          // complete
          storage
            .ref(`${authState.user._id}/series`)
            .child(filename)
            .getDownloadURL()
            .then((url) => {
              console.log("url", url);
              setuploadedImage([{ ...info.file, status: "done", url }]);
            });
          message.success(`${info.file.name} file uploaded successfully.`);
          setUploadingImage(false);
        }
      );
    },
    onRemove: () => {
      setuploadedImage([]);
      setImageUploadProgress(0);
    },
    fileList: uploadedImage,
    // beforeUpload,
  };

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    if (uploadedImage.length < 1) {
      message.error("Kindly uploaded series art");
      return;
    }
    setSubmitting(true);
    try {
      const response = await api.createSeries({
        ...values,
        image: uploadedImage[0].url,
        // seriesData:
      });
      const payload = [...dashboardState.series, response.data];
      console.log(response.data);
      dispatch({ type: UPDATE_SERIES, payload });
      message.success("Series added successfully");
      form.resetFields();
      setuploadedImage([]);
      onClose();
    } catch (error) {
      console.log(error);
      error.response
        ? message.error(error.response.data.message)
        : message.error("There was an issue with your request, kindly retry");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title="Create new series"
      visible={visible}
      okText="Create Series"
      footer={null}
      // onOk={this.handleOk}
      onCancel={() => onClose()}
    >
      <Form
        className="uploadForm"
        size="large"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="title"
          label="Series Title"
          required={false}
          rules={[
            {
              required: true,
              message: "Please input your series title!",
            },
          ]}
        >
          <Input placeholder="Your Message title" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Series Description"
          required={false}
          rules={[
            {
              required: true,
              message: "Please enter series description!",
            },
          ]}
        >
          <TextArea placeholder="This message is about..." rows={4} />
        </Form.Item>
        <Dragger {...props} accept="image/*" className="uploadBox">
          <img
            style={{ marginRight: "8px" }}
            src={require("./../../images/icons/file-arrow.svg")}
          />
          <p className="ant-upload-text">Drag and drop your image</p>
          <p className="ant-upload-hint">
            or <span className="blueText">browse</span> to choose a file
          </p>
        </Dragger>
        <div style={{ marginBottom: 40 }}>
          {uploadingImage && (
            <Progress
              percent={imageUploadProgess}
              size="small"
              status="active"
            />
          )}
        </div>
        <div className="text-right">
          <Button
            type="primary"
            htmlType="submit"
            className="bottomCTA"
            disabled={submitting}
          >
            {submitting ? "Creating..." : "Create Series"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddSeriesModal;
